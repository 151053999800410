import { v4 as uuidv4 } from "uuid";
import Card from "./carrossel3d/Card";
import Carousel from "./carrossel3d/Carousel";  // Atualize a importação
import portfolio from "../../assets/portfolio.jpg";
import ecommerce from "../../assets/e-commerce.jpg";
import desktop from "../../assets/desktop.jpeg";

function Servicos() {
  const cards = [
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={portfolio}
          title="Portfólio"
          description="O portfólio é um conjunto de trabalhos, projetos e realizações que você compilou para demonstrar suas habilidades, competências e conquistas."
        />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={ecommerce}
          title="E-Commerce"
          description="O Ecommerce – ou, em português, comércio eletrônico – é um modelo de vendas que usa a internet para comercializar produtos e serviços. Ele engloba lojas online, marketplaces e até venda nas redes sociais."
        />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={desktop}
          title="Sistemas Desktop"
          description="Sistemas desktop são aqueles que podem ser usados diretamente do computador e tudo será armazenado nele, juntamente com seu software, ou seja, não precisa de acesso à internet."
        />
      )
    }
  ];

  return (
    <section id="servicos" className="servicos">
      <div className="bg-white">
        <h1 className="text-center text-3xl font-mono">Nossos Serviços</h1>
        <Carousel
          cards={cards}
          height="500px"
          width="40%"  // Ajuste a largura conforme necessário
          margin="0 auto"
          offset={2}
          showArrows={true}  // Mostrar setas de navegação
        />
      </div>
    </section>
  );
}

export default Servicos;

import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './styles.css';

// Acesse as variáveis de ambiente aqui
const EMAIL_SERVICE_ID = process.env.REACT_APP_EMAIL_SERVICE_ID;
const EMAIL_TEMPLATE_ID = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
const EMAIL_USER_ID = process.env.REACT_APP_EMAIL_USER_ID;

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [status, setStatus] = useState('');
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateEmail = (email) => {
    // Expressão regular para validar o formato do e-mail
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Resetar os erros
    setErrors({});

    // Validar e-mail
    if (!validateEmail(formData.email)) {
      setErrors({ email: 'O e-mail fornecido não é válido.' });
      return;
    }

    try {
      const result = await emailjs.sendForm(
        EMAIL_SERVICE_ID,
        EMAIL_TEMPLATE_ID,
        e.target,
        EMAIL_USER_ID
      );
      console.log('E-mail enviado:', result.text);
      setStatus('Mensagem enviada com sucesso!');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Erro ao enviar a mensagem:', error);
      setStatus('Erro ao enviar a mensagem.');
    }
  };

  return (
    <section id="contact" className="py-6 dark:bg-gray-100 dark:text-gray-900">
      <div className="grid max-w-6xl grid-cols-1 px-6 mx-auto lg:px-8 md:grid-cols-2 md:divide-x">
        <div className="py-6 md:py-0 md:px-6">
          <h1 className="text-4xl font-bold">Entre em contato</h1>
          <p className="pt-2 pb-4">Preencha o formulário para iniciar uma conversa</p>
          <div className="space-y-4">
            <p className="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
                <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
              </svg>
              <span>Tubarão, SC</span>
            </p>
            <p className="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
              </svg>
              <span>(48)98817-4195</span>
            </p>
            <p className="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
              </svg>
              <span>patrickzm290493@gmail.com</span>
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit} noValidate className="flex flex-col py-6 space-y-6 md:py-0 md:px-6">
          <label className="block">
            <span className="mb-1 font-mono">Insira seu nome:</span>
            <input type="text" name="name" placeholder="  nome" value={formData.name} onChange={handleChange} className="block w-full rounded-md border-2 border-blue-700 shadow-sm focus:ring focus:ring-opacity-75" required />
          </label>
          <label className="block">
            <span className="mb-1 font-mono">Insira seu email:</span>
            <input type="email" name="email" placeholder="  email" value={formData.email} onChange={handleChange} className="block w-full rounded-md border-2 border-blue-700 shadow-sm focus:ring focus:ring-opacity-75 " required />
            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
          </label>
          <label className="block">
            <span className="mb-1 font-mono">Mensagem</span>
            <textarea name="message" rows="3" value={formData.message} onChange={handleChange} className="block w-full rounded-md border-2 border-blue-700 focus:ring focus:ring-opacity-75  textarea-disabled" required></textarea>
          </label>
          <button type="submit" className="self-center px-8 py-3 text-lg bg-blue-700 font-mono text-white rounded focus:ring hover:ring focus:ring-opacity-75 ">Enviar</button>
          {status && <p className="text-center mt-4">{status}</p>}
        </form>
      </div>
    </section>
  );
}

export default Contact;

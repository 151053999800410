import fotoImp from "../../assets/importancia-site.png";

function Feature (){
  return(

    <section className="text">
			<h1 className="text-center text-2xl font-mono my-6">Benefícios de possuir um site:</h1>
	<div className="container flex flex-col-reverse mx-auto lg:flex-row">
		<div className="flex flex-col px-6 py-8 space-y-6 rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 ">
			<div className="flex space-x-2 sm:space-x-4">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-shrink-0 w-6 h-6">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
				</svg>
				<div className="space-y-2">
					<p className="text-lg font-medium leading-snug">Para expadir seus negócios</p>
					<p className="leading-snug">Se você ainda questiona por que uma empresa precisa ter um site, reflita: você conhece alguma marca de sucesso que não tenha um site próprio? Independentemente do segmento, local ou tamanho, esse é o denominador comum entre todos os negócios de sucesso. Ter um site próprio é a presença digital mínima de que sua empresa precisa.</p>
				</div>
			</div>
			<div className="flex space-x-2 sm:space-x-4">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-shrink-0 w-6 h-6">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
				</svg>
				<div className="space-y-2">
					<p className="text-lg font-medium leading-snug">Para ser encontrado no Google</p>
					<p className="leading-snug">A pesquisa do Google é amplamente usada por usuários que buscam soluções e serviços para seus problemas. Portanto, quando alguém procurar por “advogado civil trabalhista”, ou “arquitetos”, um site próprio passaria mais autoridade para os robôs de busca.</p>
				</div>
			</div>
			<div className="flex space-x-2 sm:space-x-4">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-shrink-0 w-6 h-6">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
				</svg>
				<div className="space-y-2">
					<p className="text-lg font-medium leading-snug">Para ganhar autoridade no mercado</p>
					<p className="leading-snug">Com um site, sua empresa possui muito mais artifícios para promover o seu produto. Além do mais, por meio da criação de conteúdo você consegue provar para os seus futuros clientes que entende do assunto e que tem capacidade técnica para ser a solução dos problemas que ela enfrenta. É um diferencial bastante eficiente nessa tomada de decisão.</p>
				</div>
			</div>
		</div>
		<div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-100">
  <div className="flex items-center justify-center w-auto p-4 md:p-8 lg:p-12">
    <img
      src={fotoImp}
      alt=""
      className="rounded-lg shadow-lg dark:bg-gray-500 aspect-video sm:min-h-96 object-cover"
    />
  </div>
</div>

	</div>
</section>
  );
}

export default Feature;

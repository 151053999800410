import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Certifique-se de que este caminho está correto
import foto1 from '../../assets/portfolio.jpg';
import foto2 from '../../assets/e-commerce.jpg';
import foto3 from '../../assets/e-commerce2.jpg';

const images = [foto1, foto2, foto3];

const Carrossel = () => {
  return (
    <div style={{ width: '95%', margin: '0 auto', position: 'relative' }}>
      <Carousel
        infiniteLoop
        autoPlay
        interval={3000} // Define o intervalo entre as trocas de slides (em milissegundos)
        showThumbs={false}
        showStatus={false}
        showIndicators={true} // Mostrar indicadores
        swipeable
        dynamicHeight
        transitionTime={500} // Tempo da transição entre slides
      >
        {images.map((img, idx) => (
          <div key={idx} style={{ position: 'relative' }}>
            <img src={img} alt={`Slide ${idx}`} style={{ width: '100%', height: 'auto', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.2)' }} />
            <div style={{ position: 'absolute', bottom: '10%', left: '5%', color: '#fff', fontSize: '1.5rem', backgroundColor: 'rgba(0,0,0,0.5)', padding: '10px', borderRadius: '5px' }}>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Carrossel;
